import { dayjs } from '@attendu/utils';

export default ({
  startDate,
  endDate,
  timezone,
}) => {
  const days = [];

  if (endDate) {
    const daysBetween = dayjs(endDate).tz(timezone).startOf('day').diff(dayjs(startDate).tz(timezone).startOf('day'), 'day');
    for (let i = 0; i <= daysBetween; i += 1) {
      days.push(dayjs(startDate).add(i, 'day').format());
    }
  } else if (startDate) {
    days.push(startDate);
  }

  return days;
};
